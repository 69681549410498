<template>
    <div class="test-content">
        <el-button type="primary" @click="getIdCard"></el-button>
    </div>
</template>

<script>
    export default {
        name: "test",
        data() {
            return {}
        },
        mounted() {},
        methods: {
            getIdCard() {
                axios.get('/ZKIDROnline/ScanReadIdCardInfo?OP-DEV=1&CMD-URL=4&REPEAT=1&READTYPE=1')
                    .then((res) => {
                        // let idCard=JSON.stringify(res.data)

                        console.log('身份证返回:' + this.transformJson(res.data).Certificate.Name)
                    })
            }
            ,

            transformJson(values){
                let replace = values.replace(/\\/g,"/");
               return  JSON.parse(replace)
            }
        }
    }
</script>

<style scoped>

    .test-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

</style>